const heroElement = document.querySelector('.hero');
// only execute if it's the heroes on the homepage and the viewport is less than 1000px
if (heroElement && heroElement.classList.contains('hero--home') && window.innerWidth < 1000) {
    
    // get the hero__item and get the height
    const heroItem = document.querySelector('.carousel--hero .hero__item');
    const heroItemHeight= Math.floor(heroItem.getBoundingClientRect().height);

    // get the hero__img and get the height
    const heroImage = document.querySelector('.carousel--hero .hero__img');
    const heroImageHeight = Math.floor(heroImage.getBoundingClientRect().height);

    // calculate the bottom position for the slick dots, add 15px for the correct gap
    const bottom = heroItemHeight - heroImageHeight + 15;

    // apply the bottom position value to the slick dots
    const heroDots = document.querySelector('.carousel--hero .slick-dots');
    heroDots.style.bottom = `${bottom}px`;
} 
